import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonDialog = makeShortcode("ButtonDialog");
const GoTo = makeShortcode("GoTo");
const Paper = makeShortcode("Paper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "increased-risk-of-family-violence-during-the-pandemic"
    }}>{`Increased risk of family violence during the pandemic`}</h2>
    <p><strong parentName="p">{`Why is the risk of violence increased?`}</strong></p>
    <p>{`There is no single cause of family violence. It is a complex problem that can affect anyone at any time. However, some of the risk factors for family violence are increased during the pandemic. For example, financial and emotional stressors are associated with risk of violence in the home. We know from previous disasters (e.g. natural disasters) that rates of family violence increase following stressful events.`}<sup>{`1,2`}</sup></p>
    <p>{`During the pandemic, the local restrictions, including lockdowns and school closures, mean that people are spending more time at home together and have reduced access to outside supports.
This may lead to more conflict, which can escalate into violence for some families. It can also make it harder for people to seek help when they need it.`}</p>
    <p>{`This means that some young people will be at increased risk of violence during the pandemic `}{`—`}{` this includes abuse, neglect, intimate partner violence, and sexual violence.`}</p>
    <p>{`Remember, violence is never okay, and it’s important to seek help if you or your teen are at risk.`}</p>
    <ButtonDialog linkText="What should I do if my teen or myself are at risk?" title="If you think your family is at risk of experiencing violence at home, it’s important to:" mdxType="ButtonDialog">
  <ul>
    <li>
      Take some time to familiarise yourself with community resources and support services in your area. This might
      include housing services (including emergency shelters), counselling services (including chat, email or other
      digital options) and phone helplines. 1800 RESPECT (1800 737 732) provides free, 24/7 counselling for anyone in
      Australia. For more resources, check out our <GoTo to="/resources" mdxType="GoTo">Useful Resources</GoTo> page.
    </li>
    <li>
      Make a family safety plan. It’s important that your teen is aware of any parts of the plan that concern them (e.g.
      knowing a trusted adult or helpline they can contact if needed). See the{' '}
      <GoTo to="/resources" mdxType="GoTo">Useful Resources</GoTo> page for some resources on safety planning.
    </li>
  </ul>
    </ButtonDialog>
    <Paper elevation={0} style={{
      backgroundColor: '#bce2e3',
      borderRadius: '20px'
    }} mdxType="Paper">
  <b>
    Remember, even during a lockdown, you can <span style={{
          textDecoration: 'underline'
        }}>always</span> leave your
    home to escape harm or the risk of harm.
  </b>
  <br />
  If you or someone else is in immediate danger, call 000.
    </Paper>
    <sup>
  1,2. See <GoTo to="/appendix" mdxType="GoTo">Appendix</GoTo> page for full reference.
    </sup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      